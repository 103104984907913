<template>
  <div
    v-click-outside="onClickOutside"
    class="comments__wrapper"
    :class="{ on_top: onTop }"
  >
    <svg-icon
      v-if="canComment || hasComments"
      :name="hasComments ? 'tooltip' : 'tooltip_empty'"
      :class="hasComments ? 'comments__icon' : 'no-comments__icon'"
      @click.native="toggleOpen"
    />
    <div v-if="isOpen" class="comments__block">
      <div v-for="(date, key, id) in commentsByDates" :key="id">
        <div class="comments__header">
          <div class="comments__header-text">{{ key }}</div>
          <div v-if="id == 0" class="cross-icon">
            <svg-icon
              class="cross-icon"
              name="cross"
              @click.native="toggleOpen"
            />
          </div>
        </div>
        <div class="comments__main">
          <!-- {{ date.length }} -->
          <div
            v-for="(comment, id_2) in date"
            :key="id_2"
            class="comments__main-comment"
          >
            <div class="comment__header">
              <div
                v-if="id_2 == 0 && comment.user"
                class="comment__header-title"
              >
                {{ comment.user }}
              </div>
              <div class="comment__header-date subtitle">
                {{ comment.time }}
              </div>
            </div>
            <div class="comment__text">
              <p>
                {{ comment.message }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <input
        v-if="canComment"
        v-model="commentText"
        class="comments__input"
        type="text"
        :placeholder="$t('tooltip.comment_placeholder')"
      />
      <div v-if="canComment" class="buttons__block">
        <Button white small @click.native="toggleOpen"
          >{{ $t('buttons.cancel') }}
        </Button>
        <Button small @click.native="leaveComment"
          >{{ $t('buttons.send') }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import { convertDateToCurrentTimeZone } from '@/library/time';
import { revertToCamelCase } from '@/library/helpers';

export default {
  props: {
    uuid: {
      type: String,
      default: '',
    },
    canComment: {
      type: Boolean,
      default: false,
    },
    commentTo: {
      type: String,
      default: '',
    },
    onTop: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      commentText: '',
      isOpen: false,
      newComments: [],
      commentsEl: null,
    };
  },

  computed: {
    ...mapGetters([
      'getCurrentRole',
      'getCommentsHotel',
      'getCommentsDart',
      'getCurrentUser',
      'getCommentsSecretary',
      'getCommentsPresiding',
      'getCommentsCommissioner',
      'getCommentsDeputyPresiding',
      'getReportsComments',
      // 'getCommentsCategorizations',
    ]),
    newCommentsLength() {
      return !!this.newComments.length;
    },
    hasComments() {
      return !!this.comments?.length || this.newCommentsLength;
    },

    comments() {
      if (this.commentTo === 'report') {
        return !!this.getReportsComments
          ? this.getReportsComments[this.uuid]
          : [];
      } else {
        switch (this.getCurrentUser?.type) {
          case 'hotel': {
            return this.getCommentsHotel[this.uuid]
              ? this.getCommentsHotel[this.uuid]
              : [];
          }
          case 'dart':
            return this.getCommentsDart[this.uuid]
              ? this.getCommentsDart[this.uuid]
              : [];
          case 'secretary_of_the_commission':
            return this.getCommentsSecretary[this.uuid]
              ? this.getCommentsSecretary[this.uuid]
              : [];
          case 'presiding_commissioner':
            return this.getCommentsPresiding[this.uuid]
              ? this.getCommentsPresiding[this.uuid]
              : [];
          case 'deputy_presiding_commissioner':
            return this.getCommentsDeputyPresiding[this.uuid]
              ? this.getCommentsDeputyPresiding[this.uuid]
              : [];
          case 'commissioner':
            return this.getCommentsCommissioner[this.uuid]
              ? this.getCommentsCommissioner[this.uuid]
              : [];
          default:
            return [];
        }
      }
    },

    commentsByDates() {
      let commentsByDates = {};
      let allComments = [];
      if (!!this.comments?.length) {
        allComments = [...this.comments, ...this.newComments];
      } else {
        allComments = this.newComments;
      }
      for (let comment in allComments) {
        const { date, time } = convertDateToCurrentTimeZone(
          allComments[comment]?.created_at
        );

        if (!commentsByDates[date]) {
          commentsByDates[date] = [];
        }
        commentsByDates[date].push({
          message: allComments[comment].message,
          user: allComments[comment].user,
          time: time,
        });
      }
      return commentsByDates;
    },

    handlerName() {
      return `addComment${revertToCamelCase(this.commentTo)}${revertToCamelCase(
        this.getCurrentRole
      )}`;
    },
  },

  methods: {
    onClickOutside(event) {
      this.isOpen = false;
    },
    toggleOpen() {
      this.isOpen = !this.isOpen;
    },
    leaveComment() {
      if (this.commentText) {
        var now = moment.utc();

        let newComment = {
          created_at: now.format('DD.MM.YYYY HH:mm'),
          user: this.getCurrentUser.full_name,
          message: this.commentText,
        };
        this.newComments.push(newComment);
        let key =
          this.commentTo === 'report'
            ? 'report_uuid'
            : 'categorization_application_uuid';
        // categorization_application_uuid: this.$route.params.id,
        let payload = {
          criterion_uuid: this.uuid,
          comment: this.commentText,
          [key]: this.$route.params.id,
        };
        this.commentText = '';
        this.$store.dispatch(this.handlerName, payload);
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.comments__wrapper
  position: absolute
  bottom: 0
  right: 0

.on_top
  top: -3rem

.comments__icon
  max-height: 2.5rem
  cursor: pointer

.no-comments__icon
  max-height: 2rem
  cursor: pointer

.comments__block
  background-color: $white
  position: absolute
  right: 4rem
  top: 0
  min-width: 30rem
  border: 0.1rem solid $gray_light
  border-radius: 1.2rem
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08)
  font-size: 1.4rem
  z-index: 100
  opacity: 1


.comments__header
  display: flex
  justify-content: space-between
  align-items: center
  padding: 1rem 2rem
  border-bottom: 0.1rem solid $gray_light

.cross-icon
  display: flex
  align-items: center
  height: 1rem

.comments__main
  font-size: 1.2rem

.comment__header
  display: flex
  justify-content: space-between
  align-items: center
  padding: 1rem 2rem

.comment__header-title
  font-weight: bold

.comment__text
  padding: 1rem 2rem
  margin-bottom: 1rem

.comments__input
  border-top: 0.1rem solid $gray_light
  padding: 1rem 2rem
  width: 100%
  &::placeholder
    color: $gray
    font-size: 1.2rem

.buttons__block
  padding: 1rem 2rem
  display: flex
  justify-content: end
  gap: 1rem
</style>
