export default [
  {
    path: '/auth',
    component: () => import('@/components/auth/Auth.vue'),
    children: [
      {
        path: '',
        name: 'auth-password',
        component: () => import('@/components/auth/login/AuthPassword.vue'),
        meta: {
          name: 'auth',
        },
      },
      {
        path: '/tour-operator/sign-in',
        name: 'auth-key-tour-operator',
        component: () => import('@/components/auth/login/AuthKey.vue'),
        meta: {
          name: 'auth',
        },
      },
      {
        path: '/hotels/sign-in',
        name: 'auth-key-hotels',
        component: () => import('@/components/auth/login/AuthKey.vue'),
        meta: {
          name: 'auth',
        },
      },
      {
        path: '/reset-password',
        name: 'reset-password',
        component: () =>
          import('@/components/auth/password_recovery/ResetPass.vue'),
        meta: {
          name: 'auth',
        },
      },
      {
        path: '/create-password',
        name: 'create-password',
        component: () =>
          import('@/components/auth/password_recovery/CreatePass.vue'),
        meta: {
          name: 'auth',
        },
      },
      // {
      //   path: '/registration',
      //   name: 'registration',
      //   component: () => import('@/components/auth/Registration.vue'),
      // },
      // {
      //   path: '/registration/2',
      //   name: 'registration2',
      //   component: () => import('@/components/auth/RegistrationTwoPage.vue'),
      // },
    ],
  },
];
