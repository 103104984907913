<template>
  <div class="modal__container-inner">
    <div class="add-key-modal add-key-modal__wrapper">
      <h3 class="modal__title title-h4">
        {{ $t('modals.choose_key_type') }}
      </h3>
      <EuKey />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EuKey from '@/elements/euKey/EuKey.vue';

export default {
  components: { EuKey },

  data() {
    return {};
  },
  computed: {
    ...mapGetters('modal', ['getModalProps', 'getModalHandler']),
  },

  methods: {
    closeModal() {
      this.$store.commit('modal/SET_MODAL', { state: false });
    },

    async acceptHandler() {
      const { name, payload } = this.getModalHandler;

      await this.$store.dispatch(name, payload);
      this.closeModal();
    },
  },
};
</script>

<style lang="sass" scoped>
.add-key-modal__wrapper
  width: 100%
  padding: 0 60px
  @include xxs
    padding: 0 20px

.accept__box *
  text-align: center

.accept__content
  color: $gray
  margin-bottom: 60px
</style>
