import { Axios, API_VERSION, config } from '@/api/config';

// export const getAuthUserInfo = () => {
//   const url = `${API_VERSION}/authorization/user`;
//   return Axios.get(url);
// };

export const getCategorizationFormStructure = (data) => {
  const { uuid, lang } = data;
  let categorizationData = !!uuid
    ? `?categorization_application_uuid=${uuid}`
    : '';

  const url = `${API_VERSION}/hotel/categorization/form/structure${categorizationData}`;
  config.headers['Accept-Language'] = lang;
  return Axios.get(url, config);
};

export const getCategorizationHotel = (uuid) => {
  const url = `${API_VERSION}/hotel/categorization/form/application?categorization_application_uuid=${uuid}`;
  return Axios.get(url);
};

export const addCategorizationRequestDraft = (data) => {
  const url = `${API_VERSION}/hotel/categorization/form/create/as-draft`;
  return Axios.post(url, data);
};

export const editCategorizationRequest = (data) => {
  const url = `${API_VERSION}/hotel/categorization/form/application/edit`;
  return Axios.post(url, data);
};

export const createFullCategorizationRequest = (data) => {
  const url = `${API_VERSION}/hotel/categorization/form/create/full`;
  return Axios.post(url, data);
};

export const signCategorizationRequest = (data) => {
  // const url = `${API_VERSION}/hotel/categorization/form/application/sign?categorization_application_uuid=${data.categorization_application_uuid}`;
  const url = `${API_VERSION}/hotel/categorization/form/application/sign/`;
  return Axios.post(url, data);
};
export const getTableOfCategorizationsHotel = (data) => {
  const url = `${API_VERSION}/hotel/categorization/table`;
  return Axios.get(url, { params: data });
};
export const getCommentsHotel = (uuid) => {
  const url = `${API_VERSION}/hotel/categorization/form/application/comments?categorization_application_uuid=${uuid}`;
  return Axios.get(url);
};

export const getIsHotelRequestsTableExistsDraft = () => {
  const url = `${API_VERSION}/hotel/categorization/table/exists-draft`;
  return Axios.get(url);
};

export const deleteCategorizationRequest = (data) => {
  const url = `${API_VERSION}/hotel/categorization/form/application`;
  return Axios.delete(url, { params: data });
};

export const existingCategorizationInCurrentPeriod = () => {
  const url = `${API_VERSION}/hotel/categorization/table/created-application-in-current-period`;
  return Axios.get(url);
};
