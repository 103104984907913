import jwt_decode from 'jwt-decode';
import axios from 'axios';
import store from '@/store';
import router from '@/router';

// let cancelSource;

export const checkUserToken = () => {
  const token = JSON.parse(localStorage.getItem('token'));

  if (token) {
    const decoded = jwt_decode(token);
    return Date.now() >= decoded.exp * 1000 ? false : token;
  }

  return false;
};

const authInterceptor = (config) => {
  const token = checkUserToken();

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  // if (cancelSource) {
  //   cancelSource.cancel('Previous request was canceled due to a new request.');
  // }

  // cancelSource = axios.CancelToken.source();
  // config.cancelToken = cancelSource.token;

  return config;
};


const config = {
  baseURL: process.env.VUE_APP_API_HOST,
  timeout: 60 * 1000, // Timeout
  headers: {
    'Accept-Language': 'uk',
  },
};

const errorComposer = (error) => {
  const statusCode = !!error?.response?.status ? error.response.status : null;
  if (statusCode === 401) {
    router.push({ name: 'auth' });
    store.commit('LOGOUT_USER');
    return;
  }
  return () => {
    console.log('>>> errorComposer', statusCode);
  };
};

const Axios = axios.create(config);

const API_VERSION = '/api/v1.0';

Axios.interceptors.request.use(authInterceptor);

Axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (axios.isCancel(error)) {
      return
    } else {
      error.handleGlobally = errorComposer(error);
    }
    return Promise.reject(error);
  }
);

export { Axios, API_VERSION, config };
