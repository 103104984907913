export default [
  {
    path: '/access-administrator',
    name: 'access_administrator',
    component: () => import('@/components/access_admin/Main.vue'),
    redirect: { name: 'access-administrator_hotels-list' },

    children: [
      {
        path: 'hotels',
        name: 'access-administrator_hotels-list',
        component: () => import('@/components/access_admin/HotelsList.vue'),
        meta: {
          isMenu: true,
        },
      },
      {
        path: 'hotels/:id',
        name: 'access-administrator_hotel-user',
        component: () => import('@/components/access_admin/HotelUser.vue'),
        meta: {
          isMenu: true,
        },
      },
      {
        path: 'tour-operators',
        name: 'access-administrator_tour-operators-list',
        component: () =>
          import('@/components/access_admin/TourOperatorsList.vue'),
        meta: {
          isMenu: true,
        },
      },
      {
        path: 'tour-operators/:id',
        name: 'access-administrator_tour-operator-user',
        component: () =>
          import('@/components/access_admin/TourOperatorUser.vue'),
        meta: {
          isMenu: true,
        },
      },
      {
        path: 'dart',
        name: 'access-administrator_dart-list',
        component: () => import('@/components/access_admin/DartsList.vue'),
        meta: {
          isMenu: true,
        },
      },
      {
        path: 'commissioner',
        name: 'access-administrator_commissioner-list',
        component: () =>
          import('@/components/access_admin/CommissionersList.vue'),
        meta: {
          isMenu: true,
        },
      },
      {
        path: 'content-administrator',
        name: 'access-administrator_content-admin-list',
        component: () =>
          import('@/components/access_admin/ContentAdministratorList.vue'),
        meta: {
          isMenu: true,
        },
      },
      {
        path: 'content-administrator/:id',
        name: 'access-administrator_content-admin-review-user',
        component: () =>
          import('@/components/access_admin/ContentAdministratorUser.vue'),
        meta: {
          name: 'create_content_administrator_user',
          isMenu: true,
        },
      },
      {
        path: 'content-administrator/create',
        name: 'access-administrator_content-admin-create-user',
        component: () =>
          import('@/components/access_admin/ContentAdministratorUser.vue'),
        meta: {
          name: 'create_content_administrator_user',
          isMenu: false,
          goBack: -1,
        },
      },
      {
        path: 'access-administrator-user',
        name: 'access-administrator_access-admin-list',
        component: () =>
          import('@/components/access_admin/AccessAdministratorList.vue'),
        meta: {
          isMenu: true,
        },
      },
      {
        path: 'access-administrator-user/:id',
        name: 'access-administrator_access-admin-review-user',
        component: () =>
          import('@/components/access_admin/AccessAdministratorUser.vue'),
        meta: {
          name: 'create_access_administrator_user',
          isMenu: true,
          // goBack: -1,
        },
      },
      {
        path: 'access-administrator-user/create',
        name: 'access-administrator_access-admin-create-user',
        component: () =>
          import('@/components/access_admin/AccessAdministratorUser.vue'),
        meta: {
          name: 'create_access_administrator_user',
          isMenu: true,
        },
      },
      {
        path: 'actions-log',
        name: 'access-administrator_actions-log-list',
        component: () => import('@/components/access_admin/ActionsLog.vue'),
        meta: {
          isMenu: true,
        },
      },
      {
        path: 'profile',
        name: 'access-administrator_profile',
        component: () => import('@/components/access_admin/Profile.vue'),
        meta: {
          isMenu: true,
        },
      },
      {
        path: 'dart/create',
        name: 'access-administrator_dart-create-user',
        component: () => import('@/components/access_admin/DartUser.vue'),
        meta: {
          name: 'create_dart_user',
          isMenu: true,
          // goBack: -1,
        },
      },
      {
        path: 'dart/:id',
        name: 'access-administrator_dart-review-user',
        component: () => import('@/components/access_admin/DartUser.vue'),
        meta: {
          name: 'create_dart_user',
          isMenu: true,
          // goBack: -1,
        },
      },
      {
        path: 'commissioner/create',
        name: 'access-administrator_commissioner-create-user',
        component: () =>
          import('@/components/access_admin/CommissionerUser.vue'),
        meta: {
          name: 'create_commissioner_user',
          isMenu: true,
          // goBack: -1,
        },
      },
      {
        path: 'commissioner/:id',
        name: 'access-administrator_commissioner-review-user',
        component: () =>
          import('@/components/access_admin/CommissionerUser.vue'),
        meta: {
          name: 'create_commissioner_user',
          isMenu: true,
          // goBack: -1,
        },
      },
    ],
  },
];
