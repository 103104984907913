import { Axios, API_VERSION } from '@/api/config';

export const getTableOfHotelsAccessAdmin = (data) => {
  const url = `${API_VERSION}/access-administrator/users/hotels/table`;
  return Axios.get(url, { params: data });
};

export const getTableOfTourAccessAdmin = (data) => {
  const url = `${API_VERSION}/access-administrator/users/tour-operators/table`;
  return Axios.get(url, { params: data });
};

export const getTableOfDartAccessAdmin = (data) => {
  const url = `${API_VERSION}/access-administrator/users/darts/table`;
  return Axios.get(url, { params: data });
};

export const getTableOfCommissionerAccessAdmin = (data) => {
  const url = `${API_VERSION}/access-administrator/users/commissioners/table`;
  return Axios.get(url, { params: data });
};

export const getTableOfPresidingAccessAdmin = (data) => {
  const url = `${API_VERSION}/access-administrator/users/presiding-commissioners/table`;
  return Axios.get(url, { params: data });
};

export const getTableOfSecretaryAccessAdmin = (data) => {
  const url = `${API_VERSION}/access-administrator/users/secretary-of-the-commissions/table`;
  return Axios.get(url, { params: data });
};

export const getTableOfDeputyAccessAdmin = (data) => {
  const url = `${API_VERSION}/access-administrator/users/deputy-presiding-commissioners/table`;
  return Axios.get(url, { params: data });
};

export const getTableOfContentAccessAdmin = (data) => {
  const url = `${API_VERSION}/access-administrator/users/content-administrators/table`;
  return Axios.get(url, { params: data });
};

export const getTableOfAccessAccessAdmin = (data) => {
  const url = `${API_VERSION}/access-administrator/users/table`;
  return Axios.get(url, { params: data });
};

export const getUserAccessAdmin = (uuid) => {
  const url = `${API_VERSION}/access-administrator/users`;
  return Axios.get(url, { params: { user_uuid: uuid } });
};

export const createDartAccessAdmin = (data) => {
  const url = `${API_VERSION}/access-administrator/users/darts/create`;
  return Axios.post(url, data);
};

export const updateDartAccessAdmin = (data) => {
  const url = `${API_VERSION}/access-administrator/users/darts/update`;
  return Axios.post(url, data);
};

export const createCommissionerAccessAdmin = (data) => {
  const url = `${API_VERSION}/access-administrator/users/commissioners/create`;
  return Axios.post(url, data);
};

export const updateCommissionerAccessAdmin = (data) => {
  const url = `${API_VERSION}/access-administrator/users/commissioners/update`;
  return Axios.post(url, data);
};

export const createSecretaryAccessAdmin = (data) => {
  const url = `${API_VERSION}/access-administrator/users/secretary-of-the-commissions/create`;
  return Axios.post(url, data);
};

export const updateSecretaryAccessAdmin = (data) => {
  const url = `${API_VERSION}/access-administrator/users/secretary-of-the-commissions/update`;
  return Axios.post(url, data);
};

export const createDeputyAccessAdmin = (data) => {
  const url = `${API_VERSION}/access-administrator/users/deputy-presiding-commissioners/create`;
  return Axios.post(url, data);
};

export const updateDeputyAccessAdmin = (data) => {
  const url = `${API_VERSION}/access-administrator/users/deputy-presiding-commissioners/update`;
  return Axios.post(url, data);
};

export const createContentAdminAccessAdmin = (data) => {
  const url = `${API_VERSION}/access-administrator/users/content-administrators/create`;
  return Axios.post(url, data);
};

export const updateContentAdminAccessAdmin = (data) => {
  const url = `${API_VERSION}/access-administrator/users/content-administrators/update`;
  return Axios.post(url, data);
};

export const createAccessAdminAccessAdmin = (data) => {
  const url = `${API_VERSION}/access-administrator/users/create`;
  return Axios.post(url, data);
};

export const updateAccessAdminAccessAdmin = (data) => {
  const url = `${API_VERSION}/access-administrator/users/update`;
  return Axios.post(url, data);
};

export const getEventLogTabelAccessAdmin = (data) => {
  const url = `${API_VERSION}/access-administrator/event-log/table`;
  return Axios.get(url, { params: data });
};

export const getEventLogActionsAccessAdmin = () => {
  const url = `${API_VERSION}/access-administrator/event-log/actions`;
  return Axios.get(url);
};
