export default [
  {
    filing_status: 'submitted',
    review_status: 'approved',
    buttons: ['print', 'downloadCertificate', 'downloadPDF'],
    comments_visible: true,
    can_comment: false,
    edit: 'not_allowed',
    schedule: 'none',
  },
  {
    filing_status: 'submitted',
    review_status: 'declined',
    buttons: ['print'],
    comments_visible: false,
    can_comment: false,
    edit: 'not_allowed',
    schedule: 'none',
  },
  {
    filing_status: 'submitted',
    review_status: 'pending_review',
    buttons: ['sendForImprovement', 'sendToComission', 'leaveUnpended'],
    comments_visible: true,
    can_comment: true,
    edit: 'not_allowed',
    schedule: 'none',
  },
  {
    filing_status: 'submitted',
    review_status: 'waiting_for_voting_date',
    buttons: ['print'],
    comments_visible: true,
    can_comment: false,
    edit: 'not_allowed',
    schedule: 'none',
  },
  {
    filing_status: 'submitted',
    review_status: 'waiting_for_voting_date_signed',
    buttons: ['print'],
    comments_visible: true,
    can_comment: false,
    edit: 'not_allowed',
    schedule: 'none',
  },
  {
    filing_status: 'submitted',
    review_status: 'waiting_for_voting',
    buttons: ['print'],
    comments_visible: true,
    can_comment: false,
    edit: 'not_allowed',
    schedule: 'none',
  },
  {
    filing_status: 'draft',
    review_status: 'needs_improvement',
    buttons: ['print'],
    comments_visible: true,
    can_comment: false,
    edit: 'not_allowed',
    schedule: 'none',
  },
];
