export const menu = [
  {
    name: 'tour_operator_reports',
    path_name: 'presiding-commissioner_tour-operator-reports',
    rooth_name: 'tour-operator-report'
  },
  {
    name: 'vote',
    path_name: 'presiding-commissioner_votes-list',
    rooth_name: 'vote'
  },
  {
    name: 'vote_history',
    path_name: 'presiding-commissioner_history-list',
    rooth_name: 'history'
  },
  {
    name: 'profile',
    path_name: 'presiding-commissioner_profile',
    rooth_name: 'profile'
  },
];
